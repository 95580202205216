<template>
    <div ref="element" class="element-experience border shadow" @click="selected=!selected">
        <div ref="header" class="header bg-secondary py-0">
            <!-- header title -->
            <div class="header-title px-1">
                <div v-text="getHeader.id"></div>
                <div
                    class="ml-1"
                    v-html="getHeader.title"
                    v-b-tooltip.bottom.hover.v-primary.html="getHeader.tooltip"
                ></div>
            </div>
        </div>
        <!-- body -->
        <div class="body bg-white">
            <!-- pictures -->
            <div class="body-images img-container pr-1"
                 :class="selected ? 'element-select' : null"
                 @click.stop=""
                 @dblclick="showImages()"
            >
                <!-- no image -->
                <b-img
                    v-if="getPictures.length === 0"
                    fluid-grow
                    class="rounded-0"
                    src="@/assets/images/default.jpg"
                    :style="{'max-height': imageHeight}"
                />
                <!-- carousel -->
                <b-carousel
                    v-else
                    id="experience-carousel"
                    v-model="slide"
                    :interval="0"
                    background="#ababab"
                    fade
                    controls
                    indicators
                >
                    <b-carousel-slide v-for="picture in getPictures">
                        <template #img>
                            <b-img
                                fluid-grow
                                class="rounded-0"
                                :src="picture"
                                :style="{'max-height': imageHeight}"
                            />
                        </template>
                    </b-carousel-slide>
                </b-carousel>
            </div>
            <!-- content -->
            <div class="body-content" :class="selected ? 'element-select' : null">
                <!-- title -->
                <div ref="bodyTitle" class="px-1 font-weight-bolder" style="font-size: 1.2em">
                    {{getContent.title}}
                </div>
                <!-- subtitle, duration -->
                <div
                    v-if="!getContent.hide"
                    ref="bodySubtitle"
                    class="pl-1 pr-2 font-weight-bolder text-secondary "
                    style="font-size: 0.8em"
                >
                    {{getContent.subTitle}}
                    <!-- duration -->
                    <div class="text-secondary font-weight-bolder d-inline-block" style="float: right">
                        <b-icon-clock/>
                        {{getContent.duration}}
                    </div>
                </div>
                <!-- categories, types -->
                <div class="categories-types overflow-auto" :style="{'max-height': layoutCategoriesTypesHeight}">
                    <!-- categories -->
                    <span v-for="category in getContent.categories">
                        <b-badge class="mr-1" variant="secondary"
                                 style="font-size: 10px">{{category.category}}</b-badge>
                        <!-- types -->
                            <b-badge v-for="(type, index) in category.types" class="mr-1 " variant="primary"
                                     style="font-size: 10px">{{type}}</b-badge>
                    </span>
                </div>
            </div>
        </div>
        <!-- footer -->
        <div ref="footer" class="footer bg-info-light px-1">
            <!-- footer content -->
            <div class="footer-title mr-1 text-secondary">
                <!-- show detail button -->
                <div>
                    <b-icon-geo-alt-fill
                        class="cursor"
                        variant="secondary"
                        scale="1.2"
                        v-b-tooltip.topleft.hover.v-info.html="$t('adventure.experience.tooltip_experience_detail')"
                        @click.stop="showDetails()"
                    />
                </div>
                <!-- center: text -->
                <div class="flex-grow-1 text-center"
                     v-html="getFooter.htmlText"
                     v-b-tooltip.top.hover.v-primary.html="getFooter.tooltip"
                />
                <!-- add or remove button -->
                <div class="d-flex justify-content-center bg-white">
                    <b-icon-trash-fill
                        v-if="included"
                        class="cursor bg-white"
                        variant="danger"
                        scale="1.3"
                        v-b-tooltip.topright.hover.v-info.html="$t('adventure.experience.remove_button')"
                        @click.stop="$emit('eventIncluded')"
                    />
                    <b-icon-plus-square-fill
                        v-else
                        v-show="!addingToRouteDisabled"
                        class="bg-white cursor"
                        :variant="'secondary'"
                        scale="1.3"
                        v-b-tooltip.topright.hover.v-info.html="$t('adventure.experience.add_button')"
                        @click.stop="$emit('eventIncluded')"
                    />
                </div>
            </div>

        </div>
    </div>
</template>

<script>

// @group ADVENTURE2
export default {
    name: "ElementExperience",
    components: {},
    props: {
        /*
        * Element data.
        * <br>
        * <p>[{
        *       "destinationId": "number",
        *       "experienceId": "number",
        *       "destinationName": "string",
        *       "selected": "boolean: false",
        *       "address":{"street": "string", "city": "string", "postOffice": "string", "state": "string", "region": "string", "continent": "string"},
        *       "pictures":
        *       {
        *           "experience":
        *           [{
        *                   "id": "number",
        *                   "key": "string",
        *                   "image":{"src": "string path", "src_p": "string preview path", "type": "string (jpg, png...)", "width": "number", "height": "number", "size_bytes": "number"},
        *                   "meta": {"alt": "string", "name": "string", "author": "string"},
        *                   "locale": ""
        *               }],
        *           "destination": [{"id": "number", "key": "string", "image": "## Same as image in experience."}]
        *       }
        *   }]</p>
        */
        data: {
            type: Object,
            default() {
                return {}
            }
        },
        included: {
            type: Boolean,
            default: false
        },
        addingToRouteDisabled: {
            type: Boolean,
            default: false
        },
        /*
* Hotlink for marking selected markers
* <p>
*   {"destinationId": "number", "experienceId": "number", "selected": "boolean", "popupOpen": "boolean"}
*</p>
*/
        hotlink: {
            type: Object,
            default() {
                return {
                    destinationId: null,
                    experienceId: null,
                    selected: false,
                }
            }
        },
    },
    data() {
        return {
            layoutCategoriesTypesHeight: null,
            imageHeight: null,
            slide: 0,
        }
    },
    mounted() {
        this.setLayoutImageHeight();
        this.setLayoutCategoriesTypesHeight();
    },
    methods: {
        // <-- layout -->
        setLayoutImageHeight() {
            let element = this.$refs.element.clientHeight;
            let header = this.$refs.header === undefined ? 0 : this.$refs.header.clientHeight;
            let footer = this.$refs.footer === undefined ? 0 : this.$refs.footer.clientHeight;
            // <--  -->
            let height = element - header - footer;
            height -= 6;
            // <--  -->
            this.imageHeight = height + 'px';
        },
        setLayoutCategoriesTypesHeight() {
            let element = this.$refs.element.clientHeight;
            let header = this.$refs.header === undefined ? 0 : this.$refs.header.clientHeight;
            let footer = this.$refs.footer === undefined ? 0 : this.$refs.footer.clientHeight;

            let bodyTitle = this.$refs.bodyTitle === undefined ? 0 : this.$refs.bodyTitle.clientHeight;
            let bodySubtitle = this.$refs.bodySubtitle === undefined ? 0 : this.$refs.bodySubtitle.clientHeight;
            // <--  -->
            let height = element - header - footer;
            height -= (bodyTitle + bodySubtitle);

            height -= 8;
            // <--  -->
            this.layoutCategoriesTypesHeight = height + 'px';
        },
        showDetails() {
            this.$emit('eventShowDetails', {
                destinationId: this.data.destinationId,
                experienceId: this.data.experienceId,
            });
        },
        showImages() {
            // console.log("showImages");
        },
        // <-- data -->
        getCategoriesTypes(experienceTypes) {
            // <-- categories -->
            let categoriesSet = new Set();
            experienceTypes.forEach(value => {
                categoriesSet.add(value.categoryName);
            });
            let categories = Array.from(categoriesSet);
            categories.sort();
            // <-- types -->
            let categoriesTypes = [];
            let types = [];
            categories.forEach(category => {
                types = [];
                experienceTypes.forEach(record => {
                    if (category === record.categoryName) {
                        types.push(record.name);
                    }
                });
                categoriesTypes.push(
                    {
                        category: category,
                        types: types
                    }
                );
            });
            // <--  -->
            return categoriesTypes;
        },
    },
    computed: {
        selected: {
            get() {
                if (this.__isNull(this.data)) {
                    return false;
                }

                if ((this.hotlink.experienceId === null && this.data.destinationId === this.hotlink.destinationId) || this.data.experienceId === this.hotlink.experienceId) {
                    return this.hotlink.selected;
                }
                return false;

                // DEPRECATED
                // return this.data.selected;
            },
            set(value) {
                /*
                * Trigger on selected change.
                * <p>
                * return:<br>
                *   {"destinationId": "number", "experienceId": "number", "selected": "boolean"}
                *</p>
                 */
                this.$emit('eventSelected', {
                    destinationId: this.data.destinationId,
                    experienceId: this.data.experienceId,
                    selected: this.data.experienceId !== this.hotlink.experienceId ? true : value
                });
            }
        },
        getHeader() {
            if (this.__isNull(this.data)) {
                return {
                    id: '#0',
                    title: this.$t('form.no_data'),
                }
            }
            // <--  -->
            let data = this.data;
            // <-- address -->
            if (this.__isNull(this.data.address)) {
                return {
                    id: '#' + data.experienceId,
                    title: `<b>${data.destinationName}</b>`,
                    tooltip: null,
                }
            }

            let address = this.data.address;
            let tooltip = `<b>${data.destinationName}</b>`;

            tooltip += this.__isNull(address.street) ? '' : '<br>' + address.street;
            tooltip += this.__isNull(address.postOffice) ? '' : '<br>' + address.postOffice;
            tooltip += !this.__isNull(address.city) && this.__isNull(address.postOffice) ? '<br>' + address.city : '';

            tooltip += this.__isNull(address.region) ? '' : '<br>' + address.region;
            tooltip += this.__isNull(address.state) ? '' : '<br>' + address.state;
            tooltip += this.__isNull(address.continent) ? '' : '<br>' + address.continent;
            // <--  -->
            return {
                id: '#' + data.experienceId,
                title: `<b>${data.destinationName}</b>${this.__isNull(address.city) ? '' : ', ' + address.city}`,
                tooltip: tooltip
            }
        },
        getPictures() {
            let picturesSet = new Set();
            let pictures = this.data.pictures;
            // <-- experience pictures -->
            pictures.experience.forEach(picture => {
                picturesSet.add(this.__getImageSrc(picture.image.src_p));
            });
            // <-- destination pictures -->
            pictures.destination.forEach(picture => {
                picturesSet.add(this.__getImageSrc(picture.image.src_p));
            });
            // <--  -->
            return Array.from(picturesSet);
        },
        getContent() {
            if (this.__isNull(this.data) || this.__isNull(this.data.content)) {
                return {
                    title: this.$t('form.no_data'),
                    hide: true,
                    subTitle: null,
                    duration: null,
                    categories: [],
                }
            }
            // <--  -->
            let content = this.data.content;
            // <-- duration -->
            let duration = this.__isNull(content.duration.days) ? '' : content.duration.days + ' d';
            let time = content.duration.time;
            if (!this.__isNull(content.duration.time)) {
                duration += duration.length === 0 ? '' : ', ';
                time = time.indexOf('0') === 0 ? time.substring(1) : time;   // replace 0a:bc with a:bc
                duration += this.__isNull(content.duration.time) ? '' : time + ' h';
            }
            // <--  -->
            return {
                title: content.title,
                subTitle: content.subTitle,
                duration: duration,
                categories: this.getCategoriesTypes(content.experienceTypes),
            }
        },
        getFooter() {
            if (this.__isNull(this.data) || this.__isNull(this.data.bestPrice)) {
                return {
                    htmlText: this.$t('form.welcome'),
                    tooltip: null,
                }
            }
            // <--  -->
            let data = this.data;
            let person = this.$tc('adventure.experience.adult', 1);
            // let amount = '&nbsp;' + this.__getCurrencyLocale(data.bestPrice.amount);
            let currency = this.data.bestPrice.currency;
            let amount = '&nbsp;' + this.__getDecimalLocale(data.bestPrice.amount) + ' ' + currency;
            // <-- season -->
            let tooltip = null;
            if (!this.__isNull(data.bestPrice.season)) {
                let season = data.bestPrice.season;
                // tooltip = this.__isNull(season.label) ? '' : season.label + ':';
                tooltip = this.$tc('adventure.season', 0) + ':';
                tooltip += this.__isNull(season.start) ? '' : '<br>' + this.__convertBaseDateToLocaleDateString(season.start);
                tooltip += !this.__isNull(season.start) && !this.__isNull(season.end) ? ' - ' : '';
                tooltip += this.__isNull(season.end) ? '' : this.__convertBaseDateToLocaleDateString(season.end);
            }
            // <--  -->
            return {
                htmlText: this.$t('adventure.sider_experience.best_price', {type: person}) + `<b>${amount}</b>`,
                tooltip: tooltip,
            }
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>
.element-experience {
    width: 320px;
    height: 190px;
    /*max-height: 190px;*/
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.header {
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 5px 5px 0 0;
}

.header-title {
    flex: 1 0 auto;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: smaller;
}

.header-buttons {
    padding-bottom: 6px;
}

.body {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
}

.body-images {
    /*background: #fd7e14;*/
    min-width: 30%;
    max-width: 30%;
    flex: 1 0 auto;
}

.image {
    /*width: 100% \9;*/
    object-fit: contain;
    object-position: center;
    vertical-align: center;
}

.body-content {
    flex: 1 0 auto;
    max-width: 70%;
    display: flex;
    flex-direction: column;
}

.element-select {
    background: #FDFF2F;
    /*background: #F800E6;*/
    /*background: #2AFB08;*/
}

.categories-types {
    /*background: #17a2b8;*/
    flex: 1 0 auto;
}

.footer {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 5px 5px;
}

.footer-title {
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.footer-buttons {
    border: #20c997;
}

.cursor {
    cursor: pointer;
}
</style>

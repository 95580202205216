<template>
    <div class="overflow-auto" v-resize @resize="layoutTemplateResize" :style="{height: getTemplateHeight.px}">
        <!-- title -->
        <div class="pl-2 d-flex align-items-center" v-resize @resize="layoutHeaderResize" style="height: 30px">
            {{$t('adventure.sider_experience.new_adventure')}}
        </div>
        <!-- body -->
        <splitpanes class="default-theme" :style="{height:getBodyHeight.px}">
            <!-- pane: experiences, new route -->
            <pane :min-size="getPaneSize.min" :max-size="getPaneSize.max" :size="getPaneSize.size">
                <splitpanes class="default-theme">
                    <!-- experiences -->
                    <pane :min-size="10" :max-size="90">
                        <panel-adventure
                            :title="$t('adventure.available_experiences') + ` (${listExperiences.length})`"
                            :label1-text="null"
                            :fixed-width="false"
                            :width="paneMinWidth.px"
                            :max-height="getBodyHeight.value"
                            :draggableOff="newRouteEditMode"
                            @refresh="loadExperiencesFromBase()"
                            :list="listExperiences"
                            :scroll-index="scrollIndex.experiences"
                        >
                            <!-- body -->
                            <template #draggable-body-1>
                                <div v-for="(experience, index) in listExperiences" class="pb-2 pr-2 d-inline-block">
                                    <element-experience
                                        :ref="'refExperience_' + experience.experienceId"
                                        :data="experience"
                                        :addingToRouteDisabled="newRouteEditMode"
                                        :hotlink="hotlink"
                                        @eventIncluded="addToRoute(experience, index)"
                                        @eventSelected="eventSelected"
                                        @eventShowDetails="eventShowExperienceDetails"
                                    ></element-experience>
                                </div>
                            </template>
                        </panel-adventure>
                    </pane>
                    <!-- new route, edit routes -->
                    <pane>
                        <!-- mode: new routes -->
                        <panel-adventure
                            v-show="!newRouteEditMode"
                            :title="$t('adventure.sider_experience.new_route') + ` (${__isNull(route.experiences) ? 0 : route.experiences.length})`"
                            :button-refresh-visible="false"
                            :label1-text="$tc('adventure.experience.main', 1)"
                            :fixed-width="false"
                            :width="this.paneMinWidth.px"
                            :max-height="getBodyHeight.value"
                            :place-holder-visible="true"
                            :placeholder-text="$t('adventure.drag_experience_here')"
                            :list="route.experiences"
                            :scroll-index="scrollIndex.routeExperiences"
                        >
                            <!-- panel: toolbar  -->
                            <template #toolbar>
                                <b-button-toolbar class="button-toolbar py-1 d-flex">
                                    <!-- button new -->
                                    <b-button size="sm" @click="newRoute()"
                                              v-b-tooltip.topright.hover.v-info.html="$t('form.add')">
                                        <b-icon-plus
                                            scale="1.8"
                                        />
                                    </b-button>
                                    <!-- button edit -->
                                    <div v-if="isCreatingDisabled" class="d-inline-block"
                                         v-b-tooltip.topright.hover.v-danger.html="$t('adventure.route.noLogin_message.edit')">
                                        <b-button class="ml-1" size="sm" disabled>
                                            <b-icon-file-text scale="1.2"/>
                                        </b-button>
                                    </div>
                                    <b-button v-else class="ml-1 py-0" size="sm"
                                              v-b-tooltip.topright.hover.v-info.html="$t('form.edit')"
                                              @click.stop="editModeRoute()"
                                    >
                                        <b-icon-file-text scale="1.2"/>
                                    </b-button>
                                    <!-- button save -->
                                    <div v-if="isCreatingDisabled" class="d-inline-block"
                                         v-b-tooltip.topcenter.hover.v-danger.html="$t('adventure.route.noLogin_message.save')">
                                        <b-button class="ml-1" size="sm" disabled>
                                            <b-icon-save scale="1.2"/>
                                        </b-button>
                                    </div>
                                    <b-button v-else class="ml-1 py-0" size="sm"
                                              v-b-tooltip.topcenter.hover.v-info.html="$t('form.save')"
                                              @click.stop="saveRoute(false)"
                                    >
                                        <b-icon-save scale="1.2"/>
                                    </b-button>
                                    <!-- button publish -->
                                    <div v-if="isCreatingDisabled" class="d-inline-block"
                                         v-b-tooltip.topcenter.hover.v-danger.html="$t('adventure.route.noLogin_message.publish')">
                                        <b-button class="ml-1" size="sm" disabled>
                                            <b-icon-check scale="1.8"/>
                                        </b-button>
                                    </div>
                                    <b-button v-else class="ml-1 py-0" size="sm"
                                              v-b-tooltip.topcenter.hover.v-info.html="$t('form.save_publish')"
                                              @click.stop="saveRoute(true)"
                                    >
                                        <b-icon-check scale="1.8"/>
                                    </b-button>
                                    <!-- button delete -->
                                    <div v-if="isCreatingDisabled" class="d-inline-block"
                                         v-b-tooltip.topleft.hover.v-danger.html="$t('adventure.route.noLogin_message.delete')">
                                        <b-button class="ml-3" size="sm" disabled>
                                            <b-icon-trash variant="danger" scale="1.2"/>
                                        </b-button>
                                    </div>
                                    <b-button v-else size="sm" class="ml-3" :disabled="!route.id > 0"
                                              v-b-tooltip.topleft.hover.v-info.html="$t('form.delete')"
                                              @click.stop="deleteSelectedRoute()"
                                    >
                                        <b-icon-trash variant="danger" scale="1.2"/>
                                    </b-button>

                                </b-button-toolbar>
                            </template>
                            <!-- panel: body 0 -->
                            <template #body-0>
                                <!-- info -->
                                <div class="my-1 pl-1 pr-1">
                                    <!-- route title -->
                                    <div class="pr-2 d-inline-block">
                                        <b-form-group
                                            :label="$t('adventure.title') + '*'"
                                            description=""
                                            class="mb-0"
                                            label-class="mb-0"
                                            label-for="title"
                                            style="width: 320px"
                                        >
                                            <b-form-input
                                                id="title"
                                                v-model="route.name"
                                                type="text"
                                                :state="nameValidity"
                                            ></b-form-input>
                                            <b-form-invalid-feedback :state="nameValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </div>
                                </div>
                            </template>
                            <!-- panel: draggable-body 1 -->
                            <template #draggable-body-1>
                                <!-- route experiences -->
                                <div v-for="(experience, index) in route.experiences"
                                     class="pb-2 pr-2 d-inline-block">
                                    <element-experience
                                        :data="experience"
                                        :included="true"
                                        :hotlink="hotlink"
                                        @eventIncluded="removeFromRoute(experience, index)"
                                        @eventSelected="eventSelected"
                                        @eventShowDetails="eventShowRouteExperienceDetails"
                                    ></element-experience>
                                </div>
                            </template>
                        </panel-adventure>
                        <!-- mode:edit routes -->
                        <panel-adventure
                            v-show="newRouteEditMode"
                            :title="$t('adventure.personal_adventure') + ` (${routes.length})`"
                            :button-refresh-visible="false"
                            :label1-text="null"
                            :fixed-width="false"
                            :width="this.paneMinWidth.px"
                            :max-height="getBodyHeight.value"
                            :place-holder-visible="false"
                            :placeholder-text="$t('adventure.drag_experience_here')"
                            :list="routes"
                        >
                            <!-- panel: toolbar  -->
                            <template #toolbar>
                                <b-button-toolbar class="button-toolbar py-1 d-flex">
                                    <!-- button edit -->
                                    <b-button size="sm" @click="newRouteEditMode=false">
                                        <b-icon-arrow-left
                                            scale="1.2"
                                            v-b-tooltip.topright.hover.v-info.html="$t('adventure.back_to_adventure')"
                                        />
                                    </b-button>
                                    <!-- button new -->
                                    <b-button size="sm" class="ml-1" @click="newRoute()"
                                              v-b-tooltip.topright.hover.v-info.html="$t('form.add')">
                                        <b-icon-plus
                                            scale="1.8"
                                        />
                                    </b-button>
                                </b-button-toolbar>
                            </template>
                            <!-- panel: draggable-body 1 -->
                            <template #draggable-body-1>
                                <div v-for="route in routes" class="py-1">
                                    <div class="px-1 d-flex justify-content-between"
                                         :class="route.active ? 'bg-secondary' : 'bg-primary-light'">
                                        <div>
                                            {{route.name}}
                                        </div>
                                        <div class="d-flex align-items-center" style="width: 60px">
                                            <!-- button edit -->
                                            <b-icon-file-text
                                                class="mr-1"
                                                :variant="route.active ? 'primary' : 'secondary'"
                                                style="cursor: pointer;"
                                                scale="1.0"
                                                v-b-tooltip.topright.hover.v-info.html="$t('form.edit')"
                                                @click="editRoute(route.id)"
                                            />
                                            <!-- button publish -->
                                            <b-icon-check
                                                v-if="route.active"
                                                class="mr-1"
                                                variant="primary"
                                                scale="1.4"
                                                v-b-tooltip.topright.hover.v-info.html="$t('form.published')"
                                            />
                                            <b-icon-check-square
                                                v-else
                                                class="mr-1"
                                                variant="secondary"
                                                style="cursor: pointer;"
                                                scale="1.0"
                                                v-b-tooltip.topright.hover.v-info.html="$t('form.publish')"
                                                @click="publishRoute(route.id)"
                                            />
                                            <!-- button delete -->
                                            <b-icon-trash
                                                variant="danger"
                                                style="cursor: pointer;"
                                                scale="1.0"
                                                v-b-tooltip.topright.hover.v-info.html="$t('form.delete')"
                                                @click="deleteRoute(route.id, route.name, false)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </panel-adventure>
                    </pane>
                </splitpanes>
            </pane>
            <!-- pane: leaflet map -->
            <pane>
                <!-- <leaflet-map-->
                <panel-map class="m-1" style="width:100%">
                    <template #map>
                        <leaflet-map2
                            :destinations="getDestinations.destinations"
                            :routing="getRouting"
                            :hotlink="hotlink"
                            @popupOpen="popupOpen"
                            @eventClearHotlink="clearHotlink()"
                        >
                            <!-- map body -->
                            <template #popupBody>
                                <popup-destination2
                                    :data="popupData"
                                    :hotlink.sync="hotlink"
                                />
                            </template>
                        </leaflet-map2>
                    </template>
                </panel-map>
            </pane>
        </splitpanes>
    </div>
</template>

<script>
import PanelAdventure from "@/components/_public/_adventures2/elements/PanelAdventure";
import PanelMap from "@/components/_public/_adventures2/elements/PanelMap";
import ElementExperience from "@/components/_public/_adventures2/elements/ElementExperience";
import LeafletMap2 from "@/components/_public/_adventures2/elements/LeafletMap2";
import PopupDestination2 from "@/components/_public/_adventures2/elements/PopupDestination2";

// @group ADVENTURE2
export default {
    name: "CreateRoute",
    components: {PopupDestination2, LeafletMap2, ElementExperience, PanelMap, PanelAdventure},
    props: {},
    data() {
        return {
            newRouteEditMode: false,
            /**
             * @vuese
             * Use for calculating layout heights and/or widths.
             */
            templateLayout: {},
            /**
             * @vuese
             * Use for calculating layout heights and/or widths.
             */
            layout: [],
            // <-- pane experience -->
            paneMinWidth: {
                value: 320,
                px: 190 + 'px',
            },
            // <-- pane map -->
            paneMapMinWidth: {
                value: 480,  // <-- should bi modal window width -->
                px: 480 + 'px'
            },
            // <-- data -->
            listExperiencesDefault: [],
            listExperiences: [],
            destinations: [],
            routeDefault: {
                id: null,
                owner_id: null,
                contractor_id: null,
                name: null,
                content: {},
                locale: {
                    'en_GB': {
                        'name': null,
                        'subtitle': null,
                        'description': null
                    },
                    'sl_SI': {
                        'name': null,
                        'subtitle': null,
                        'description': null
                    }
                },
                extended: {
                    travelDates: [],
                    transporter: [],
                    insurance: [],
                },
                // <-- pictures -->
                pictures_order: [],
                pictures: [],
                // <-- experiences -->
                experiences: [],
                // <--  -->
                order: 0,
                active: false,
            },
            route: {},
            routes: [],
            popupData: {},
            scrollIndex: {
                experiences: null,
                routeExperiences: null
            },
            validityCheck: false,
            // <-- leafletMap -->
            hotlink: {
                source: null,
                destinationId: null,
                experienceId: null,
                selected: false,
                popupOpen: false,
            },
            // <-- base -->
            /**
             * @vuese
             * URLs for calling api
             */
            uri: {
                main: `api/v1/adventure2/routes2`,
                uri1: `api/v1/adventure2/route`,
                getPrivateRoutes: `api/v1/adventure2/getPrivateRoutes`,
                publishRoute: `api/v1/adventure2/publish`,
                getExperiences: `api/v1/_public/get_experiences`,
                getDestination: `api/v1/_public/get_destination`,
            },
        }
    },
    mounted() {
        this.newRoute();
        this.loadExperiencesFromBase();
    },
    methods: {
        // @ignore <-- layout -->
        layoutTemplateResize(e) {
            this.templateLayout = {
                width: e.detail.width,
                height: e.detail.height,
            };
        },
        /**
         * @vuese
         * Added layout data (width, height) for calculating page or component optimal size (height).
         * @arg event
         */
        layoutHeaderResize(e) {
            let layout = this.__layoutAdd(this.layout, 'title', e.detail.width, e.detail.height);
        },
        /**
         * @vuese
         * Move the slider to the experience.
         * @arg experienceId
         */
        scrollToExperience() {
            let offset = 0;
            this.scrollIndex = {
                experiences: null,
                routeExperiences: null
            }
            // <-- experiences -->
            if (!this.__isNull(this.listExperiences)) {
                this.listExperiences.every((experience, index) => {
                    if ((this.hotlink.experienceId === null && experience.destinationId === this.hotlink.destinationId) || experience.experienceId === this.hotlink.experienceId) {
                        this.scrollIndex.experiences = index;
                        return false;
                    }
                    return true;
                });
            }
            // <-- route experiences -->
            if (!this.__isNull(this.listRoute)) {
                this.listRoute.experiences.every((experience, index) => {
                    if ((this.hotlink.experienceId === null && experience.destinationId === this.hotlink.destinationId) || experience.experienceId === this.hotlink.experienceId) {
                        this.scrollIndex.routeExperiences = index;
                        return false;
                    }
                    return true;
                });
            }
        },
        // <-- data -->
        clearHotlink() {
            this.hotlink = {
                source: null,
                destinationId: null,
                experienceId: null,
                selected: false,
                popupOpen: false,
            }
        },
        addToRoute(experience, index) {
            // <-- remove from experiences -->
            this.listExperiences.splice(index, 1);
            // <-- add to new route -->
            // this.route.experiences.unshift(experience);
            this.route.experiences.push(experience);
        },
        removeFromRoute(experience, index) {
            // <-- remove from new route -->
            this.route.experiences.splice(index, 1);
            // <-- add to experiences -->
            this.listExperiences.unshift(experience);
        },
        getExperienceHeader(destination) {
            // <-- destinations header -->
            let destinationName = !this.__isNull(destination.locale.name) ? destination.locale.name : destination.meta.name
            let destinationPlace = destination.address.city;
            let destinationState = destination.address.state;
            let destinationContinent = destination.address.continent;
            return `${destinationName}, ${destinationPlace}, ${destinationState}`;
            // return `${destinationName}, ${destinationPlace}, ${destinationState}, ${destinationContinent}`;
        },
        getDuration(day, time) {
            // <-- remove leader 0 in time -->
            if (!this.__isNull(time) && time.substring(0, 1) === '0') {
                time = time.substring(1);
            }
            // <--  -->
            let duration = day > 0 ? day + ' ' + this.$tc('date_time.day', day === 1 ? 1 : 0).toLowerCase() : '';
            duration += time === null ? '' : ' ';
            duration += time === null ? '' : time + ' h';
            // <--  -->
            return duration;
        },
        getCategoriesTypes(experienceTypes) {
            // <-- categories -->
            let categories = [];
            experienceTypes.forEach(value => {
                categories.push(value.categoryName);
            });
            categories = this.__uniqueArray(categories);
            categories = this.__sortArray(categories);
            // <-- types -->
            let categoriesTypes = [];
            let types = [];
            categories.forEach(category => {
                types = [];
                experienceTypes.forEach(record => {
                    if (category === record.categoryName) {
                        types.push(record.name);
                    }
                });
                categoriesTypes.push(
                    {
                        category: category,
                        types: types
                    }
                );
            });
            // <--  -->
            return categoriesTypes;
        },
        setData(data, setNewRouteIds) {
            if (this.__isNull(data)) {
                return;
            }
            let arrayExperiences = [];
            let arrayRouteExperiences = [];
            let arrayDestinations = [];
            let setDestinationsIds = new Set();
            // <--  -->
            let object = {};
            let objectDestination = {};
            data.forEach((record, index) => {
                object = {
                    destinationId: record.destinationId,
                    experienceId: record.experienceId,
                    destinationName: record.destination.name,
                    address: record.destination.address.address,
                    pictures: {
                        experience: record.pictures,
                        destination: record.destination.pictures
                    },
                    content: {
                        title: record.title,
                        subTitle: record.subtitle,
                        duration: record.duration,
                        experienceTypes: record.experienceTypes
                    },
                    bestPrice: record.bestAdultPrice,
                    selected: false
                }
                // <-- destinations -->
                if (!setDestinationsIds.has(record.destinationId)) {
                    objectDestination = {
                        destinationId: record.destinationId,
                        experienceId: record.experienceId,
                        title: record.destination.name,
                        subtitle: record.destination.subtitle,
                        latLng: {
                            lat: record.destination.coordinates.latitude,
                            lng: record.destination.coordinates.longitude
                        },
                    };
                    arrayDestinations.push(objectDestination);
                    setDestinationsIds.add(record.destinationId);
                }
                // <--  -->
                if (setNewRouteIds.has(record.experienceId)) {
                    arrayRouteExperiences.push(object);
                } else {
                    arrayExperiences.push(object);
                }
            });
            // <--  -->
            this.$set(this, 'destinations', arrayDestinations);
            this.$set(this, 'listExperiences', arrayExperiences);
            this.$set(this, 'listExperiencesDefault', this.__clone(arrayExperiences));
            this.$set(this.route, 'experiences', arrayRouteExperiences);
        },
        setRoute(data) {
            let route = {};
            if (this.__isNull(data)) {
                return route;
            }
            // <--  -->
            route.id = data.id;
            route.active = data.active;
            // <-- meta -->
            route.name = data.name;
            // <-- experiences -->
            route.experiences = [];
            let reIds = [];
            let availableExperiences = this.__clone(this.listExperiences);
            let leIds = [];
            // <-- add experience to route.experiences -->
            data.experiences.sort((t1, t2) => t1.order < t2.order ? -1 : 1);
            data.experiences.forEach(er => {
                this.listExperiencesDefault.every(experience => {
                    if (er.experience_id === experience.experienceId) {
                        route.experiences.push(experience);
                        reIds.push(er.experience_id);
                        return false;
                    }
                    return true;
                });
            });
            // <-- add experienceId to leIds -->
            availableExperiences.forEach(le => {
                leIds.push(le.experienceId);
            });
            // <-- remove from available experiences -->
            this.listExperiencesDefault.forEach(experience => {
                availableExperiences.every((le, index) => {
                    if (experience.experienceId === le.experienceId) {
                        if (reIds.includes(le.experienceId)) {
                            availableExperiences.splice(index, 1);
                        }
                        return false;
                    }
                    return true;
                });
                // <-- add missing experiences -->
                if (!leIds.includes(experience.experienceId)) {
                    availableExperiences.push(experience);
                }
            });
            // <--  -->
            this.listExperiences = this.__clone(availableExperiences);
            // <--  -->
            return route;
        },
        // <-- events -->
        /*
        * When selected experienceId == null -> select all elements where destinationId matches.
        * If experienceId > 0 then select only the element where experienceId matches.
        */
        eventSelected(data) {
            data.source = this.$options.name;
            data.popupOpen = false;
            this.hotlink = data;
        },
        eventShowExperienceDetails(data) {
            let hotlink = this.hotlink;
            // <-- same experience -->
            if (data.experienceId === hotlink.experienceId) {
                hotlink.popupOpen = !hotlink.popupOpen;
            }
            // <-- other experience -->
            else {
                hotlink = data;
                hotlink.selected = true;
                hotlink.popupOpen = true;
            }
            // <--  -->
            hotlink.source = null;
            this.hotlink = hotlink;
        },
        eventShowRouteExperienceDetails(data) {
            this.eventShowExperienceDetails(data);
            let hotlink = this.hotlink;
            // <-- same experience -->
            if (data.experienceId === hotlink.experienceId) {
                hotlink.popupOpen = !hotlink.popupOpen;
            }
            // <-- other experience -->
            else {
                hotlink = data;
                hotlink.selected = true;
                hotlink.popupOpen = true;
            }
            // <--  -->
            hotlink.source = null;
            this.hotlink = hotlink;
        },
        newRoute() {
            this.validityCheck = false;
            this.newRouteEditMode = false;
            this.route = this.__clone(this.routeDefault);
            // <-- listExperiences -->
            this.listExperiences = this.__clone(this.listExperiencesDefault);
            // <-- selected -->
            this.listExperiences.forEach(experience => {
                experience.selected = experience.experienceId === this.hotlink.experienceId ? this.hotlink.selected : false;
            });
        },
        editModeRoute() {
            this.newRouteEditMode = true;
            this.loadRoutesFromBase();
        },
        editRoute(id) {
            this.newRouteEditMode = false;
            this.loadRouteFromBase(id);
        },
        saveRoute(active) {
            this.validityCheck = true;
            let validity = this.nameValidity ?? true;
            // <-- check -->
            if (validity) {
                this.validityCheck = false;
                let record = this.getRecordForSaving;
                record.active = active;
                if (record.id > 0) {
                    this.baseUpdate(record);
                    return;
                }
                this.baseInsertFormData(record);
            }
        },
        publishRoute(id) {
            this.$root.mx_busy = true;
            // <-- clear errors -->
            // this.resetErrors();
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.put(this.uri.publishRoute + `/${id}}`, {}, config)
                .then((response) => {
                    this.$set(this, 'routes', response.data);
                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    // error = error.response.data;
                    // this.errors.message = error;
                    // this.$root.mx_busy = false;
                })
                .finally(function () {
                    // always executed
                });
        },
        deleteSelectedRoute() {
            let id = this.route.id;
            let name = this.route.name;
            this.deleteRoute(id, name, true);
        },
        deleteRoute(id, name, clearSelectedRoute) {
            const h = this.$createElement;
            const messageHTML = h('div', [
                h('span', [
                    `${this.$t('form.delete_message.pre')} ${this.$tc(this.localeKey, 2)} `,
                    h('strong', name), this.$t('form.delete_message.post'),
                ])
            ]);
            // <-- message box -->
            this.$bvModal.msgBoxConfirm([messageHTML], {
                title: this.$t('form.please_confirm'),
                // size: 'sm',
                // buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('form.delete'),
                cancelTitle: this.$t('form.cancel'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value) {
                        this.baseDelete(id, clearSelectedRoute);
                    }
                })
                .catch(err => {
                    // on error executed
                })
        },
        // <-- base -->
        /**
         * @vuese
         * Load data from database
         */
        loadExperiencesFromBase() {
            this.$root.mx_busy = true;
            this.listExperiences = [];
            // <-- listNewRoute -->
            let setRouteExperiencesIds = new Set();
            this.route.experiences.forEach(routeExperience => {
                setRouteExperiencesIds.add(routeExperience.experienceId);
            });
            this.route.experiences = [];
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.getExperiences, config)
                .then((response) => {
                    this.setData(response.data, setRouteExperiencesIds);
                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
        loadRoutesFromBase() {
            this.$root.mx_busy = true;
            this.routes = [];
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <--  -->
            // this.axios.get(this.uri.main, config)
            this.axios.get(this.uri.getPrivateRoutes, config)
                .then((response) => {
                    this.$set(this, 'routes', response.data);
                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                    // this.$root.mx_busy = false;
                });
        },
        loadRouteFromBase(id) {
            this.$root.mx_busy = true;
            this.route = {};
            this.routeTemp = {};
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <--  -->
            this.axios.get(this.uri.main + `/${id}`, config)
                .then((response) => {
                    this.$set(this, 'route', this.setRoute(response.data.record));
                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                    // this.$root.mx_busy = false;
                });
        },
        baseInsertFormData(record) {
            this.$root.mx_busy = true;
            // <-- clear errors -->
            // this.resetErrors();
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- form data -->
            let formData = new FormData();
            // <-- prepare files for axios -->
            // if (record.pictures !== undefined) {
            //     record.pictures.forEach((picture, index) => {
            //         formData.append('division', this.division);
            //         formData.append('files[' + index + ']', picture.file);
            //         formData.append('keys[' + index + ']', picture.key);
            //     });
            // }
            // <-- prepare data for axios -->
            formData.append('record', JSON.stringify(record));
            // <-- axios -->
            this.axios.post(this.uri.main, formData, config)
                .then((response) => {
                    if (response.data.record.active) {
                        this.newRoute();
                    } else {
                        this.route = this.setRoute(response.data.record);
                    }
                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    error = error.response.data;
                    // this.errors.message = error;
                    // this.$root.mx_busy = false;
                })
                .finally(function () {
                    // always executed
                });
        },
        baseUpdate(record) {
            // <-- removed unnecessary items from record -->
            delete record.content;
            delete record.extended;
            delete record.locale;

            this.$root.mx_busy = true;
            // <-- clear errors -->
            // this.resetErrors();
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.put(this.uri.main + `/${record.id}`, {record: record}, config)
                .then((response) => {
                    if (response.data.record.active) {
                        this.newRoute();
                    } else {
                        this.route = this.setRoute(response.data.record);
                    }
                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    // error = error.response.data;
                    // this.errors.message = error;
                    // this.$root.mx_busy = false;
                })
                .finally(function () {
                    // always executed
                });
        },
        baseDelete(id, clearSelectedRoute) {
            this.$root.mx_busy = true;
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.delete(this.uri.main + `/${id}`, config)
                .then((response) => {
                    if (clearSelectedRoute) {
                        this.newRoute();
                    } else {
                        this.$set(this, 'routes', response.data);
                    }
                    this.$root.mx_busy = false;
                })
                .catch((error) => {
                    console.log("error:", error);
                    // this.this.mx_busy = false;
                })
                .finally(function () {
                    // always executed
                });
        },
        // <-- map popup -->
        popupOpen() {
            this.$root.mx_busy = true;
            // <-- clear old data -->
            this.$set(this, 'popupData', {});
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.getDestination + `/${this.hotlink.destinationId}`, config)
                .then((response) => {
                    this.$set(this, 'popupData', response.data.record);
                    // this.$nextTick(() => {
                    this.$root.mx_busy = false;
                    // });
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
    },
    computed: {
        getTemplateHeight() {
            let height = this.__layoutCenterHeightCalculate.value - this.$adventuresSubHeight;
            // <--  -->
            return {
                value: height,
                px: height + 'px'
            };
        },
        getBodyHeight() {
            let height = this.getTemplateHeight.value - this.__layoutHeightCalculate(this.layout);
            height -= 0;
            // <--  -->
            return {
                value: height,
                px: height + 'px'
            }
        },
        // <-- pane -->
        getPaneSize() {
            // <-- minSize -->
            let minSize = (2 * this.paneMinWidth.value + 60) * 100 / this.templateLayout.width;
            minSize = minSize.toFixed(0);
            // <-- panelMapMinSize -> maxSize  -->
            let maxSize = (this.templateLayout.width - this.paneMapMinWidth.value) * 100 / this.templateLayout.width;
            maxSize = maxSize.toFixed(2) - 0.5;
            // <-- when monitor is smaller then minSize + maxSize -->
            if (minSize > maxSize) {
                minSize = 50;
                maxSize = 50;
            }
            // <--  -->
            return {
                min: 30, // minSize,
                max: 80, // maxSize, // TODO change pane value!!!
                size: 50,
            }
        },
        // <-- base -->
        // @ignore <-- data -->
        getRecordForSaving() {
            let record = {};
            record.id = this.route.id;
            // <-- meta -->
            record.meta = {
                name: this.route.name,
                subtitle: null,
                description: null
            };
            // <-- content -->
            record.content = this.route.content;
            // <-- locale -->
            record.locale = this.route.locale;
            // <-- extended -->
            record.extended = this.route.extended;
            // <-- experiences -->
            let experiences = [];
            this.route.experiences.forEach((experience, index) => {
                experiences.push(
                    {
                        order: index,
                        experience_id: experience.experienceId
                    }
                );
            });
            record.experiences = experiences;
            // <-- accessibility -->
            record.accessibility = {};
            record.accessibility.private = true;
            // <--  -->
            return record;
        },
        // <-- Non-routing destinations -->
        getDestinations() {
            // <-- add experiences destination ids to setDestinationIds -->
            let setDestinationIds = new Set();
            if (!this.__isNull(this.route.experiences)) {
                this.route.experiences.forEach(experience => {
                    setDestinationIds.add(experience.destinationId);
                });
            }
            // <--  -->
            let destinations = [];  // <-- non route destinations -->
            let routingDestinations = [];
            this.destinations.forEach(dest => {
                if (!setDestinationIds.has(dest.destinationId)) {
                    destinations.push(dest);
                }
            });
            // <--  -->
            return {
                destinations: destinations,
            }
        },
        // <-- Routing data with destinations -->
        getRouting() {
            let route = this.route;
            // <--  -->
            if (this.__isNull(route.experiences)) {
                return [{
                    route: {
                        id: null,
                        stamp: null,
                        title: null,
                        color: null
                    },
                    destinations: []
                }];
            }
            // <--  -->
            // <-- destinations -->
            let destinations = [];
            let lastDestinationId = 0;
            let destinationTemp;
            route.experiences.forEach(experience => {
                if (experience.destinationId !== lastDestinationId) {
                    this.destinations.every(dest => {
                        if (dest.destinationId === experience.destinationId) {
                            destinationTemp = this.__clone(dest);
                            destinationTemp.experienceId = experience.experienceId;
                            destinations.push(destinationTemp);
                            return false;
                        }
                        return true;
                    });
                }
                lastDestinationId = experience.destinationId;
            });
            // <--  -->
            return [{
                route: {
                    id: null,
                    stamp: null,
                    title: route.name,
                    lineOptions: null, //{styles: [{color: 'white', opacity: 1, weight: 7}, {color: 'red', opacity: 1, weight: 2}]}
                },
                destinations: destinations,
            }];
        },
        // <-- validity -->
        nameValidity() {
            if (!this.validityCheck || this.__isNull(this.route)) {
                return null;
            }
            // <--  -->
            let validity = !this.__isNull(this.route.name);
            return validity ? null : false;
        },
        // <--  -->
        // <--  -->
        isCreatingDisabled() {
            return !this.$root.mx_isLogin;
        },
    },
    filters: {},
    watch: {
        'hotlink': {
            handler: function (value, oldValue) {
                if (value.source !== this.$options.name) {
                    // <-- scrollTo -->
                    this.scrollToExperience();
                }
            },
            deep: true
        },
    }
}
</script>

<style scoped>
.draggable-ghost {
    /*background:#fd7e14;*/
    border: gray 2px dashed;
    width: 324px; /*-- width and height are not necessary. The right and bottom margin are corrected here. --*/
    height: 194px;
    display: inline-block;
    opacity: 40%;
    border-radius: 5px;
}

</style>
